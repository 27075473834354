import { redirect } from "react-router-dom";

export function getTokenDuration() {
  const storedExpirationDate = localStorage.getItem("expiration");

  if (storedExpirationDate && typeof storedExpirationDate === "string" && storedExpirationDate.trim() !== "") {
    const expirationDate = new Date(storedExpirationDate);
    const now = new Date();

    return expirationDate.getTime() - now.getTime();
  }

  return -1;
}

export function getAuthToken() {
  const token = localStorage.getItem("token");

  if (!token) {
    return null;
  }

  const tokenDuration = getTokenDuration();

  if (tokenDuration < 0) {
    return "EXPIRED";
  }

  return token;
}

export function tokenLoader() {
  const token = getAuthToken();
  return token;
}

export function checkAuthLoader() {
  const token = getAuthToken();

  if (!token) {
    return redirect("/signin");
  }

  return null;
}

export function checkPublicLoader() {
  const token = getAuthToken();

  if (token) {
    return redirect("/");
  }

  return null;
}
