import Signin, { action as signinAction } from "../components/pages/Signin";
import Signup, { action as signupAction } from "../components/pages/Signup";
import ForgottenPassword, { action as forgottenPasswordAction } from "../components/pages/ForgottenPassword";

import { checkPublicLoader } from "../services/auth";

export default [
  {
    path: "/signin",
    element: <Signin />,
    action: signinAction,
    loader: checkPublicLoader,
  },
  {
    path: "/signup",
    element: <Signup />,
    action: signupAction,
    loader: checkPublicLoader,
  },
  {
    path: "/forgotten-password",
    element: <ForgottenPassword />,
    action: forgottenPasswordAction,
    loader: checkPublicLoader,
  },
];
