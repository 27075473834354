import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { format } from "date-fns";

import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";
import Dropdown from "react-bootstrap/Dropdown";

import Table from "../../molecules/Table";
import Loader from "../../atoms/Loader";
import Button from "../../atoms/Button";

import configRewardsImage from "../../../assets/images/config-rewards.svg";

import { ThreeDotsVertical as ActionIcon } from "react-bootstrap-icons";

import { RewardTypeThemes, getRewardTypeTheme } from "../../../enums/RewardTypes";
import { fetchConfigRewards, deleteConfigReward } from "../../../stores/Shop/ConfigReward/config-reward-actions";
import { selectConfigRewards, selectTotalItems, selectLoading, selectError } from "../../../stores/Shop/ConfigReward/config-rewards-slice";
import { selectShop } from "../../../stores/Shop/shop-slice";

interface ConfigRewardTableProps {
  theme?: string;
  withPaginate?: boolean;
}

const ConfigRewardTable: FC<ConfigRewardTableProps> = ({ theme, withPaginate }: ConfigRewardTableProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shop = useSelector(selectShop);
  const configRewards = useSelector(selectConfigRewards);
  const totalItems = useSelector(selectTotalItems);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

  const [page, setPage] = useState(1);

  const handleDeleteClick = (configRewardId: string) => {
    try {
      // @ts-ignore
      dispatch(deleteConfigReward(configRewardId));
      toast.success(t("config_reward.success.delete"));
    } catch (error) {
      toast.error(t("config_reward.errors.delete"));
    }
  };

  const handleConfigRewardPaginate = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    if (shop) {
      // @ts-ignore
      dispatch(fetchConfigRewards({ shopId: shop.id, page: page }));
    }
  }, [page, shop, dispatch]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger">
        <Alert.Heading as="h5">{t("errors.http")}</Alert.Heading>
      </Alert>
    );
  }

  const headers = [
    {
      content: t("reward.network.label"),
      className: "rounded-start",
    },
    {
      content: t("reward.type.label"),
    },
    {
      content: t("reward.value.label"),
    },
    {
      content: t("reward.validity.label"),
    },
    {
      content: t("reward.quantity.label"),
    },
    {
      content: t("reward.used_at.null"),
    },
    {
      content: t("reward.quantity_remaining.label"),
    },
    {
      content: t("reward.used_at.not_null"),
    },
    {
      content: <div className="invisible">{t("actions.label")}</div>,
      className: "rounded-end",
    },
  ];

  const rows = configRewards.map((configReward: any) => {
    const social: string = "Instagram";
    const type: string = configReward.type;
    const typeLabel: string = t(`reward.type.${type}`);
    const typeTheme: string = getRewardTypeTheme(type as keyof typeof RewardTypeThemes);
    const value: string = configReward.percent ? configReward.percent + " %" : configReward.code ?? configReward.description;
    const validityStartAt: string | null = configReward.validity_start_at ? format(new Date(configReward.validity_start_at), "dd/MM/yyyy") : null;
    const validityEndAt: string | null = configReward.validity_end_at ? format(new Date(configReward.validity_end_at), "dd/MM/yyyy") : null;
    const validity: string | null = validityStartAt && validityEndAt ? validityStartAt + " - " + validityEndAt : null;
    const quantity: number | null = configReward.quantity;
    const quantityAllocated: number | null = quantity ? configReward.quantityAllocated : null;
    const quantityUsed: number | null = quantity ? configReward.quantityUsed : null;
    const quantityRemaining: number | null = configReward.quantityRemaining;

    return [
      {
        content: social,
      },
      {
        content: (
          <Badge bg={typeTheme} className="w-100">
            {typeLabel}
          </Badge>
        ),
      },
      {
        content: value,
      },
      {
        content: validity,
      },
      {
        content: quantity,
      },
      {
        content: quantityAllocated,
      },
      {
        content: quantityRemaining,
      },
      {
        content: quantityUsed,
      },
      {
        content: (
          <Dropdown align={{ lg: "end" }}>
            <Dropdown.Toggle variant="light-subtle">
              <ActionIcon />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={Button} to={`/rewards/${type}/${configReward.id}`} label={t("actions.update")} />
              <Dropdown.Item onClick={() => handleDeleteClick(configReward.id)}>{t("actions.delete")}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];
  });

  let tableProps: any = {
    headers: headers,
    rows: rows,
    loading: loading,
    theme: theme,
  };

  if (withPaginate) {
    tableProps.paginate = { active: page, pages: Math.ceil(totalItems / 10), onPaginate: handleConfigRewardPaginate };
  }

  return (
    <>
      <Table {...tableProps} />

      {configRewards.length === 0 ? (
        <div className="text-center p-3">
          <Image src={configRewardsImage} fluid alt="" />
          <p className="mt-3">{t("config_reward.empty")}</p>
        </div>
      ) : null}
    </>
  );
};

export default ConfigRewardTable;
