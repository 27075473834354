import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Dropdown from "react-bootstrap/Dropdown";

import ConfigRewardTable from "../../../organisms/Reward/ConfigRewardTable";
import RewardTable from "../../../organisms/Reward/RewardTable";
import Button from "../../../atoms/Button";

import { selectShop } from "../../../../stores/Shop/shop-slice";

const Rewards = () => {
  const { t } = useTranslation();
  const shop = useSelector(selectShop);

  useEffect(() => {
    document.title = t("page.config_rewards.meta_title");
  }, []);

  return (
    <div className="py-3">
      <div>
        <div className="d-flex justify-content-between">
          <h3 className="fw-bold mb-2">{t("page.config_rewards.title")}</h3>
          {shop && shop.discountTypes.length ? (
            <div className="text-end mb-3">
              <Dropdown align={{ lg: "end" }}>
                <Dropdown.Toggle variant="outline-dark-blue">{t("actions.add")}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {shop.discountTypes.includes("code") && <Dropdown.Item as={Button} to="/rewards/code/add" label="un Code Promo" />}
                  {shop.discountTypes.includes("voucher") && <Dropdown.Item as={Button} to="/rewards/voucher/add" label="un Bon d'achat" />}
                  {shop.discountTypes.includes("percent") && <Dropdown.Item as={Button} to="/rewards/percent/add" label="une Réduction" />}
                  {shop.discountTypes.includes("other") && <Dropdown.Item as={Button} to="/rewards/other/add" label="Autre" />}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : null}
        </div>
        <ConfigRewardTable theme="dark-blue" withPaginate />
      </div>

      <div className="mt-5">
        <h3 className="fw-bold mb-2">{t("page.rewards.title")}</h3>
        <RewardTable theme="dark-blue" withPaginate />
      </div>
    </div>
  );
};

export default Rewards;
