import { Link } from "react-router-dom";

import Image from "react-bootstrap/Image";

import logoImage from "../../assets/images/layout/logo.png";

const Logo = (props: { withLink?: boolean }) => {
  return props.withLink ? (
    <Link to="/">
      <Image src={logoImage} fluid alt="Logo Tagether" width="120px" />
    </Link>
  ) : (
    <Image src={logoImage} fluid alt="Logo Tagether" width="120px" />
  );
};

export default Logo;
