import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../types/store";
import { fetchOwner } from "./owner-actions";

interface UserState {
  user: any; // @todo
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  user: null,
  loading: false,
  error: null,
};

const ownerSlice = createSlice({
  name: "owner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOwner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOwner.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchOwner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const {} = ownerSlice.actions;

export const selectUser = (state: RootState) => state.userOwner.user;
export const selectLoading = (state: RootState) => state.userOwner.loading;
export const selectError = (state: RootState) => state.userOwner.error;

export default ownerSlice.reducer;
