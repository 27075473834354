import React, { useEffect } from "react";
import { json, redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PublicLayout from "../layouts/PublicLayout";
import SignupForm from "../organisms/Signup/SignupForm";

const Signup = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("page.signup.meta_title");
  }, []);

  return (
    <PublicLayout>
      <h2 className="mb-5 text-center fw-bold">{t("page.signup.title")}</h2>
      <SignupForm />
    </PublicLayout>
  );
};

export default Signup;

export async function action({ request }: { request: Request }) {
  const data = await request.formData();

  const registerData = {
    shop: {
      name: data.get("name"),
      phoneNumber: data.get("phoneNumber"),
      category: data.get("category"),
      address: {
        firstRow: data.get("addressFirstRow"),
        secondRow: data.get("addressSecondRow"),
        postalCode: data.get("addressPostalCode"),
        city: data.get("addressCity"),
      },
    },
    user: {
      name: data.get("userName"),
      email: data.get("userEmail"),
      password: data.get("userPassword"),
    },
  };

  const response = await fetch(
    new Request(process.env.REACT_APP_API_ENTRYPOINT + "/users/shop-register", {
      method: "POST",
      body: JSON.stringify(registerData),
      headers: new Headers({ "Content-Type": "application/json" }),
    })
  );

  if (!response.ok) {
    return json({ message: "account.errors.signup" }, { status: 500 });
  }

  return redirect("/signin");
}
