import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";

import rewardImage from "../../../../assets/images/reward.svg";

import PublicationForm from "../../../organisms/Publication/PublicationForm";
import ConfigRewardCard from "../../../organisms/Reward/ConfigRewardCard";
import PublicationCard from "../../../organisms/Social/PublicationCard";
import Button from "../../../atoms/Button";
import Loader from "../../../atoms/Loader";

import { RootState } from "../../../../types/store";
import { getPublicationById } from "../../../../stores/Shop/Social/Instagram/instagram-slice";
import { getAuthToken } from "../../../../services/auth";
import { selectShop } from "../../../../stores/Shop/shop-slice";

const Publication = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const token = getAuthToken();
  const shop = useSelector(selectShop);

  const [reward, setReward] = useState(null);
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState("");

  // @ts-ignore
  const publication = useSelector((state: RootState) => getPublicationById(params.id)(state));

  useEffect(() => {
    document.title = t("page.publication.meta_title");
  }, []);

  useEffect(() => {
    if (!publication || (shop && shop.quota && shop.quotaRemaining === 0)) {
      navigate("/instagram/publications");
    } else {
      fetch(
        new Request(process.env.REACT_APP_API_ENTRYPOINT + `/v2/discounts/publication/${publication.id}`, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }),
        })
      )
        .then((response) => response.json())
        .then((data) => {
          setReward(data);
          setLoading(false);

          if (data) {
            navigate("/instagram/publications");
          }
        })
        .catch((error) => navigate("/instagram/publications"));
    }
  }, []);

  useEffect(() => {
    if (publication) {
      setComment(t("publication.comment.message", { username: publication.username }));
    }
  }, [publication]);

  const handleFormSubmitted = (reward: any) => {
    setReward(reward);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(comment);

    toast.success(t("publication.comment.copy"));
  };

  const handlePublicationClick = () => {
    window.open(publication.permalink, "_blank");
  };

  return (
    <>
      {publication ? (
        <Row className="justify-content-space-between gy-4">
          <Col md={12} lg={5}>
            <h4 className="fw-bold">{t("page.publication.title")}</h4>
            <PublicationCard publication={publication} />
          </Col>
          <Col lg={7}>
            {loading ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                {reward ? (
                  <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                    <div>
                      <div className="text-center">
                        <Image src={rewardImage} alt="" className="mb-3" />
                        <h3 className="mb-3">{t("publication.success.title")}</h3>
                        <h4 className="mb-3">{t("publication.success.subtitle")}</h4>
                      </div>

                      <ConfigRewardCard configReward={reward} isSelected={true} />

                      <div className="mt-5">
                        <h5 className="fw-bold">{t("publication.comment.title")} :</h5>
                        <p>{t("publication.comment.info")}</p>
                        <Form.Group controlId="publicationComment">
                          <Form.Control as="textarea" rows={6} readOnly value={comment} />
                        </Form.Group>
                      </div>
                      <div className="text-end mt-3">
                        <Button theme="outline-tagether" to="/instagram/publications" label={t("actions.back")} className="me-2" />
                        <Button theme="primary" onClick={handleCopyClick} label={t("actions.copy")} className="me-2" />
                        <Button theme="tagether" onClick={handlePublicationClick} label={t("social.instagram.view")} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <PublicationForm publication={publication} onSubmitted={handleFormSubmitted} />
                )}
              </>
            )}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <div className="text-center mt-5">
              <Loader />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Publication;
