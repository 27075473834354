import React from "react";
import { Link } from "react-router-dom";

import { Button as BootstrapButton } from "react-bootstrap";

const Button = (props: { className?: string; theme?: string; to?: string; href?: string; label: string; onClick?: any }) => {
  return (
    <>
      {props.to ? (
        <Link className={`btn btn-${props.theme} ${props.className}`} to={props.to}>
          {props.label}
        </Link>
      ) : (
        <BootstrapButton variant={props.theme} onClick={props.onClick} className={props.className}>
          {props.label}
        </BootstrapButton>
      )}
    </>
  );
};

export default Button;
