import React from "react";
import { useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ErrorPage = () => {
  const error: any = useRouteError();
  const { t } = useTranslation();

  let message = t("errors.http");

  if (error.status === 500) {
    message = t(error.data.message);
  }

  if (error.status === 404) {
    message = t("errors.http404");
  }

  toast.error(message);

  return <></>;
};

export default ErrorPage;
