import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthToken } from "../../services/auth";

export const fetchShop = createAsyncThunk("shop/fetch", async ({ shopId }: { shopId: string }) => {
  const token = getAuthToken();

  const response = await fetch(
    new Request(process.env.REACT_APP_API_ENTRYPOINT + `/shops/${shopId}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
      cache: "no-cache",
    })
  );

  if (!response.ok) {
    throw new Error("Erreur lors de la récupération de la société");
  }

  const data = await response.json();

  return data;
});

export const connectInstagramShop = createAsyncThunk(
  "shop/connectInstagram",
  async ({ shopId, accessToken }: { shopId: string; accessToken: string }) => {
    const token = getAuthToken();

    const response = await fetch(
      new Request(process.env.REACT_APP_API_ENTRYPOINT + `/v2/shops/${shopId}/instagram/connect?accessToken=${accessToken}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      })
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la connexion avec Instagram");
    }

    const data = await response.json();

    return data;
  }
);

export const fetchCategories = createAsyncThunk("shop/fetchCategories", async () => {
  const response = await fetch(
    new Request(process.env.REACT_APP_API_ENTRYPOINT + `/categories`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
  );

  if (!response.ok) {
    throw new Error("Erreur lors de la récupération des catégories");
  }

  const data = await response.json();

  return data;
});
