import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Nav from "react-bootstrap/Nav";

const AccountNavigation = () => {
  const { t } = useTranslation();

  return (
    <Nav variant="underline" defaultActiveKey="/account" className="pt-3 border-bottom">
      <Nav.Item>
        <Nav.Link as={NavLink} to="/account" end className="text-black">
          {t("account.nav.socials")}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to="/account/offer" end className="text-black">
          {t("account.nav.offer")}
        </Nav.Link>
      </Nav.Item>
      {/* <Nav.Item>
        <Nav.Link as={NavLink} to="/account/invoices" end className="text-black">
          {t("account.nav.invoices")}
        </Nav.Link>
      </Nav.Item> */}
      <Nav.Item>
        <Nav.Link as={NavLink} to="/account/password" end className="text-black">
          {t("account.nav.password")}
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default AccountNavigation;
