import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Navbar from "react-bootstrap/Navbar";

import Logo from "../../atoms/Logo";
import { selectShop } from "../../../stores/Shop/shop-slice";
import { selectUser } from "../../../stores/User/owner-slice";

const Header = () => {
  const { t } = useTranslation();

  const owner = useSelector(selectUser);
  const shop = useSelector(selectShop);

  return (
    <Navbar sticky="top" className="bg-white border-bottom pt-1 pb-1">
      <div className="header-tabbar">
        <Navbar.Brand href="/" className="justify-content-start">
          <Logo />
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text className="d-flex align-items-center text-end">
            {owner ? owner.email : null}
            {shop ? (
              <>
                <br />
                {shop.name}
              </>
            ) : null}
          </Navbar.Text>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Header;
