import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ConfigRewardForm from "../../../organisms/Reward/ConfigRewardForm";

interface ConfigRewardAddProps {}

const ConfigRewardAdd: React.FC = () => {
  const params = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("page.config_rewards.create.meta_title");
  }, []);

  return (
    <>
      <h3>{t("page.config_rewards.title")}</h3>
      <p className="text-center fw-bold p-5">{t("page.config_rewards.subtitle")}</p>
      <ConfigRewardForm type={params.type} />
    </>
  );
};

export default ConfigRewardAdd;
