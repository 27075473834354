import React, { useState, useEffect } from "react";
import { Form as RouterForm, useActionData, useNavigation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Form from "react-bootstrap/Form";

import Submit from "../../atoms/Submit";
import Button from "../../atoms/Button";

interface ErrorResponseForm {
  message: string;
}

const SigninForm = () => {
  const data: ErrorResponseForm = useActionData() as ErrorResponseForm;
  const navigation = useNavigation();
  const { t } = useTranslation();

  const isSubmitting = navigation.state === "submitting";

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  useEffect(() => {
    if (data) {
      toast(data.message);
    }
  }, [data]);

  return (
    <Form method="post" as={RouterForm} noValidate validated={validated} onSubmit={handleSubmit} onChange={() => setValidated(false)}>
      <Form.Group className="mb-3" controlId="forgottenEmail">
        <Form.Control type="email" name="email" placeholder={t("form.forgotten_password_form.email.placeholder")} required />
        <Form.Control.Feedback type="invalid">{t("form.forgotten_password_form.email.error")}</Form.Control.Feedback>
      </Form.Group>

      <div className="d-grid">
        <Submit theme="tagether" label={t("form.forgotten_password_form.submit")} loading={isSubmitting} />
        <hr />
        <Button theme="outline-tagether" label={t("form.signin_form.submit")} to={"/signin"} />
      </div>
    </Form>
  );
};

export default SigninForm;
