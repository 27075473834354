export enum RewardTypeLabels {
  percent = "Réduction",
  code = "Code Promo",
  voucher = "Bon d'achat",
  other = "Autre",
}

export const getRewardTypeLabel = (type: keyof typeof RewardTypeLabels): string => {
  return RewardTypeLabels[type] || "Libellé inconnu";
};

export enum RewardTypeThemes {
  percent = "tagether-green",
  code = "tagether-blue",
  voucher = "tagether-pink",
  other = "tagether-yellow",
}

export const getRewardTypeTheme = (type: keyof typeof RewardTypeThemes): string => {
  return RewardTypeThemes[type] || "secondary";
};

export enum RewardTypeEndpoints {
  percent = "config_discount_percents",
  code = "config_discount_codes",
  voucher = "config_discount_vouchers",
  other = "config_discount_others",
}

export const getRewardTypeEndpoint = (type: keyof typeof RewardTypeEndpoints): string => {
  return RewardTypeEndpoints[type] || null;
};
