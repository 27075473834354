import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthToken } from "../../services/auth";

export const fetchOwner = createAsyncThunk("owner/fetch", async () => {
  const token = getAuthToken();

  const response = await fetch(
    new Request(process.env.REACT_APP_API_ENTRYPOINT + `/users/me`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    })
  );

  if (!response.ok) {
    throw new Error("Erreur lors de la récupération de l'utilisateur");
  }

  const data = await response.json();

  if (!data.roles.includes("ROLE_OWNER")) {
    throw new Error("Erreur l'utilisateur n'est pas un commerçant");
  }

  if (!data.shop) {
    throw new Error("Erreur lors de la récupération de la société");
  }

  return data;
});
