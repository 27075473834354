import React from "react";
import { useParams, Outlet } from "react-router-dom";

import Socials from "../organisms/Social/Socials";

const SocialLayout = () => {
  const params = useParams();
  const isInstagram = params.social === "instagram"; // @TODO Add method
  const title = isInstagram ? "Instagram" : null; // @TODO Add method

  return (
    <>
      <Socials />
      <div className="py-3">
        {title ? <h3>{title}</h3> : null}
        <Outlet />
      </div>
    </>
  );
};

export default SocialLayout;
