import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Image from "react-bootstrap/Image";

import checkIconImage from "../../../assets/images/icons/icon-check.png";

import Loader from "../../atoms/Loader";

import { fetchAccount } from "../../../stores/Shop/Social/Instagram/instagram-actions";
import { selectShop } from "../../../stores/Shop/shop-slice";
import { selectAccount, selectError, selectLoading } from "../../../stores/Shop/Social/Instagram/instagram-slice";

interface SocialAccountProps {
  social: string;
  className?: string;
  withDetails?: boolean;
  withStatus?: boolean;
}

const SocialAccount: FC<SocialAccountProps> = ({ social, className, withDetails, withStatus }: SocialAccountProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shop = useSelector(selectShop);

  const account = useSelector(selectAccount);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

  useEffect(() => {
    if (shop && shop.instagramBusinessAccountId && shop.instagramBusinessAccountToken) {
      // @ts-ignore
      dispatch(fetchAccount({ shopInstagramId: shop.instagramBusinessAccountId, shopInstagramToken: shop.instagramBusinessAccountToken }));
    }
  }, [shop, dispatch]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center p-3">
        <Loader isSmall />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger">
        <Alert.Heading as="h5">{t("errors.http")}</Alert.Heading>
      </Alert>
    );
  }

  return (
    <div className={className ?? ""}>
      {account ? (
        <Row className="justify-content-between align-items-center">
          <Col xs={2}>
            <div className="position-relative me-3" style={{ width: "40px" }}>
              <Image src={account.profile_picture_url} roundedCircle width="40px" height="40px" className="object-fit-cover" />
              <span className="position-absolute bottom-0 end-0 p-2 bg-image bg-image--instagram border border-light rounded-circle"></span>
            </div>
          </Col>

          <Col>
            <div className="d-flex flex-column text-center">
              <span className="fw-bold">{account.name}</span>
              <span className="fw-light">
                <small>{account.website}</small>
              </span>
            </div>
          </Col>

          {withDetails ? (
            <>
              <Col>
                <div className="d-flex flex-column ms-3 text-center">
                  <span className="fw-bold">{account.media_count}</span>
                  <span className="fw-light">
                    <small>{t("social.instagram.publications")}</small>
                  </span>
                </div>
              </Col>

              <Col>
                <div className="d-flex flex-column ms-3 text-center">
                  <span className="fw-bold">{account.followers_count}</span>
                  <span className="fw-light">
                    <small>{t("social.instagram.followers")}</small>
                  </span>
                </div>
              </Col>
            </>
          ) : null}

          {withStatus ? (
            <Col>
              <div className="d-flex justify-content-end align-items-center">
                <span className="fw-bold me-1">
                  <small>
                    {!shop.instagramBusinessAccountId && !shop.instagramBusinessAccountToken ? t("social.disconnected") : t("social.connected")}
                  </small>
                </span>
                <Image src={checkIconImage} alt="" width="32px" />
              </div>
            </Col>
          ) : null}
        </Row>
      ) : null}
    </div>
  );
};

export default SocialAccount;
