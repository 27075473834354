import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import FacebookLogin from "@greatsumini/react-facebook-login";

import Button from "react-bootstrap/Button";

import { generateCSRFToken } from "../../../../utils/csrfToken";

import { connectInstagramShop } from "../../../../stores/Shop/shop-actions";
import { selectShop } from "../../../../stores/Shop/shop-slice";

const InstagramBusinessLogin = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const shop = useSelector(selectShop);

  const instagramBusinessId = process.env.REACT_APP_INSTAGRAM_BUSINESS_ID;
  const csrfToken = generateCSRFToken(32);

  // const currentURL = window.location.href;
  // const [beforeHash, afterHash] = currentURL.split("#");
  // const urlParams = new URLSearchParams(afterHash);

  // const accessToken = urlParams.get("access_token");
  // const state = urlParams.get("state");

  // useEffect(() => {
  //   if (shop && accessToken && state === csrfToken) {
  //     // @ts-ignore
  //     dispatch(connectInstagramShop({ shopId: shop.id, accessToken: accessToken }));
  //   }
  // }, [shop, accessToken, dispatch]);

  const [language, setLanguage] = useState("en_US");

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage.replace("-", "_") || "en_US");
  }, [i18n.language]);

  return (
    <>
      {instagramBusinessId && (
        <FacebookLogin
          appId={instagramBusinessId}
          language={language}
          scope="pages_read_engagement,pages_show_list,instagram_manage_comments,business_management,instagram_basic,public_profile" // @TODO : add instagram_graph_user_media,instagram_graph_user_profile
          fields="name,email,picture"
          // useRedirect
          initParams={{
            version: "v16.0",
          }}
          dialogParams={{
            redirect_uri: `${window.location.href}`,
            state: csrfToken,
            response_type: "token",
          }}
          onSuccess={async (response) => {
            if (shop && response.accessToken) {
              // @ts-ignore
              await dispatch(connectInstagramShop({ shopId: shop.id, accessToken: response.accessToken }));
              window.location.reload();
            }
          }}
          onFail={(error) => {
            // console.log("Login Failed!", error);
          }}
          onProfileSuccess={(response) => {
            // console.log("Get Profile Success!", response);
          }}
          render={({ onClick, logout }) => (
            <Button variant="tagether" className="rounded-pill" onClick={onClick}>
              {t("social.facebook.button_auth_card")}
            </Button>
          )}
        />
      )}
    </>
  );
};

export default InstagramBusinessLogin;
