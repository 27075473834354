import React, { useEffect } from "react";
import { json, redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PublicLayout from "../layouts/PublicLayout";
import ForgottenPasswordForm from "../organisms/Signin/ForgottenPasswordForm";

const ForgottenPassword = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("page.forgotten_password.meta_title");
  }, []);

  return (
    <PublicLayout withBackground>
      <h2 className="mb-3 fw-bold">{t("page.forgotten_password.title")}</h2>
      <ForgottenPasswordForm />
    </PublicLayout>
  );
};

export default ForgottenPassword;

export async function action({ request }: { request: Request }) {
  const data = await request.formData();
  const resetData = {
    email: data.get("email"),
  };

  const response = await fetch(
    new Request(process.env.REACT_APP_API_ENTRYPOINT + "/v1/reset-password", {
      method: "POST",
      body: JSON.stringify(resetData),
      headers: new Headers({ "Content-Type": "application/json" }),
    })
  );

  if (!response.ok) {
    return json({ message: "account.errors.forgotten_password" }, { status: 500 });
  }

  return redirect("/signin");
}
