import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AccountPasswordForm from "../../../organisms/Account/AccountPasswordForm";

const AccountPassword = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("page.account.password.meta_title");
  }, []);

  return (
    <>
      <h3>{t("page.account.password.title")}</h3>
      <Row>
        <Col lg={6}>
          <AccountPasswordForm />
        </Col>
      </Row>
    </>
  );
};

export default AccountPassword;
