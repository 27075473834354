import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";

import { ThreeDotsVertical as ActionIcon } from "react-bootstrap-icons";

import SocialAccount from "../../../organisms/Social/SocialAccount";

import { selectAccount } from "../../../../stores/Shop/Social/Instagram/instagram-slice";
import { disconnectAccount } from "../../../../stores/Shop/Social/Instagram/instagram-actions";
import { selectShop } from "../../../../stores/Shop/shop-slice";

const AccountSocials = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shop = useSelector(selectShop);

  const account = useSelector(selectAccount);

  useEffect(() => {
    document.title = t("page.account.socials.meta_title");
  }, []);

  const handleLogoutClick = () => {
    if (shop) {
      // @ts-ignore
      dispatch(disconnectAccount({ shopId: shop.id }));
    }
  };

  return (
    <>
      <h3>{t("page.account.socials.title")}</h3>
      <Row>
        <Col lg={12}>
          {account ? (
            <Card>
              <Card.Body className="bg-light-subtle d-flex justify-content-between align-items-center">
                <SocialAccount social="instagram" withStatus />
                <Dropdown align={{ lg: "end" }}>
                  <Dropdown.Toggle variant="light-subtle">
                    <ActionIcon />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleLogoutClick()}>{t("social.actions.logout")}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Card.Body>
            </Card>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default AccountSocials;
