import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";

import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";

import Table from "../../molecules/Table";
import Loader from "../../atoms/Loader";
import Button from "../../atoms/Button";

import rewardsImage from "../../../assets/images/rewards.svg";

import { RewardTypeThemes, getRewardTypeTheme } from "../../../enums/RewardTypes";
import { selectRewards, selectTotalItems, selectLoading, selectError } from "../../../stores/Shop/Reward/rewards-slice";
import { fetchRewards } from "../../../stores/Shop/Reward/reward-actions";
import { selectShop } from "../../../stores/Shop/shop-slice";

interface RewardTableProps {
  theme?: string;
  withPaginate?: boolean;
}

const RewardTable: FC<RewardTableProps> = ({ theme, withPaginate }: RewardTableProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shop = useSelector(selectShop);
  const rewards = useSelector(selectRewards);
  const totalItems = useSelector(selectTotalItems);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

  const [page, setPage] = useState(1);

  const handlePublicationClick = (reward: any) => {
    window.open(reward.permalink, "_blank");
  };

  const handleRewardPaginate = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    if (shop) {
      // @ts-ignore
      dispatch(fetchRewards({ shopId: shop.id, page: page }));
    }
  }, [page, shop, dispatch]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger">
        <Alert.Heading as="h5">{t("errors.http")}</Alert.Heading>
      </Alert>
    );
  }

  const headers = [
    {
      content: t("reward.network.label"),
      className: "table-rounded align-middle text-center",
    },
    {
      content: t("reward.ambassador.label"),
      className: "align-middle text-center",
    },
    {
      content: t("reward.content.label"),
      className: "align-middle text-center",
    },
    {
      content: t("reward.assigned.label"),
      className: "align-middle text-center",
    },
    {
      content: t("reward.label"),
      className: "align-middle text-center",
    },
    {
      content: t("reward.value.label"),
      className: "align-middle text-center",
    },
    {
      content: t("reward.status.label"),
      className: "table-rounded-bottom align-middle text-center",
    },
  ];

  const locale = fr;
  const rows: Array<Array<any>> = rewards.map((reward: any) => {
    const social: string = "Instagram";
    const socialUsername: string = reward.username ? "@" + reward.username : "";
    const socialMedia: string = reward.mediaId;
    const date: string = format(new Date(reward.createdAt), "MMMM - yy", { locale });
    const type: string = reward.type;
    const typeLabel: string = t(`reward.type.${type}`);
    const typeTheme: string = getRewardTypeTheme(type as keyof typeof RewardTypeThemes);
    const value: string = reward.description ?? reward.code ?? reward.amount_in_percent + " %";
    const statusLabel: string = reward.usedAt ? t("reward.used_at.not_null") : t("reward.used_at.null");

    return [
      {
        content: social,
        className: 'content-table align-middle'
      },
      {
        content: socialUsername,
        className: 'content-table align-middle'
      },
      {
        content: date,
        className: "text-capitalize",
      },
      {
        content: (
          <Badge bg={typeTheme} className="inline-block align-middle">
            {typeLabel}
          </Badge>
        ),
      },
      {
        content: value,
        className: 'text-center content-table align-middle'
      },
      {
        content: statusLabel,
        className: "fw-bold content-table align-middle",
      },
      {
        content: socialMedia ? (
          <Button theme="dark" className="btn-sm fw-bold rounded-2 content-table-media align-middle text-center" label={t("actions.view")} onClick={() => handlePublicationClick(reward)} />
        ) : (
          ""
        ),
      },
    ];
  });

  let tableProps: any = {
    headers: headers,
    rows: rows,
    loading: loading,
    theme: theme,
  };

  if (withPaginate) {
    tableProps.paginate = { active: page, pages: Math.ceil(totalItems / 10), onPaginate: handleRewardPaginate };
  }

  return (
    <>
      <Table {...tableProps} />
      <div className="text-center p-3">
        {rewards.length === 0 ? (
          <>
            <Image src={rewardsImage} fluid alt="" />
            <p className="mt-3">{t("reward.empty")}</p>
            <Button className="rounded-2" theme={theme} label={t("actions.assign")} to="/instagram/publications" />
          </>
        ) : (
          <>
            {location.pathname !== "/rewards" ? <Button className="rounded-2" theme={theme} label={t("actions.view_more")} to="/rewards" /> : null}
          </>
        )}
      </div>
    </>
  );
};

export default RewardTable;
