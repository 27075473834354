import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthToken } from "../../../../services/auth";

/** @see https://developers.facebook.com/docs/instagram-api/reference/ig-user */
export const fetchAccount = createAsyncThunk(
  "instagram/fetchAccount",
  async ({ shopInstagramId, shopInstagramToken }: { shopInstagramId: string; shopInstagramToken: string }) => {
    const response = await fetch(
      new Request(
        process.env.REACT_APP_INSTAGRAM_BUSINESS_API +
          `/${shopInstagramId}?fields=followers_count,media_count,name,profile_picture_url,username,website&access_token=${shopInstagramToken}`,
        {
          method: "GET",
        }
      )
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la récupération du compte Instagram");
    }

    const data = await response.json();

    return data;
  }
);

export const disconnectAccount = createAsyncThunk("instagram/disconnectAccount", async ({ shopId }: { shopId: string }) => {
  const token = getAuthToken();

  const response = await fetch(
    new Request(process.env.REACT_APP_API_ENTRYPOINT + `/shops/${shopId}/instagram-logout`, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    })
  );

  if (!response.ok) {
    throw new Error("Erreur lors de la déconnexion du compte Instagram");
  }

  const data = await response.json();

  return data;
});

export const fetchPublications = createAsyncThunk(
  "instagram/fetchPublications",
  async ({
    shopInstagramId,
    shopInstagramToken,
    next,
    count,
  }: {
    shopInstagramId: string;
    shopInstagramToken: string;
    next?: string | null;
    count?: number;
  }) => {
    const url =
      next ??
      process.env.REACT_APP_INSTAGRAM_BUSINESS_API +
        `/${shopInstagramId}/tags?fields=id,media_type,media_url,username,caption,permalink&access_token=${shopInstagramToken}&limit=${count || 20}`;

    const response = await fetch(
      new Request(url, {
        method: "GET",
      })
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la récupération des publications Instagram");
    }

    const data = await response.json();

    return {
      response: data,
      toAdd: !!next,
    };
  }
);

/** @see https://developers.facebook.com/docs/instagram-api/reference/ig-media */
/** @TODO need instagram_graph_user_media,instagram_graph_user_profile permission scopes to get publication informations */
export const fetchPublication = createAsyncThunk(
  "instagram/fetchPublication",
  async ({ mediaId, shopInstagramToken }: { mediaId: string; shopInstagramToken: string }) => {
    const response = await fetch(
      new Request(
        process.env.REACT_APP_INSTAGRAM_BUSINESS_API +
          `/${mediaId}?fields=id,media_type,media_url,username,caption,permalink&access_token=${shopInstagramToken}`,
        {
          method: "GET",
        }
      )
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la récupération de la publication Instagram");
    }

    const data = await response.json();

    return data;
  }
);
