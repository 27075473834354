import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";

import { Download as DownloadIcon } from "react-bootstrap-icons";

const AccountInvoices = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("page.account.invoices.meta_title");
  }, []);

  return (
    <>
      <h3>{t("page.account.invoices.title")}</h3>
      <Row>
        <Col lg={6}>
          <ListGroup>
            <ListGroup.Item action href="/account/invoices" eventKey="invoice-1">
              <div className="d-flex justify-content-between align-items-center">
                Janvier 2023
                <DownloadIcon />
              </div>
            </ListGroup.Item>
            <ListGroup.Item action href="/account/invoices" eventKey="invoice-2">
              <div className="d-flex justify-content-between align-items-center">
                Février 2023
                <DownloadIcon />
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </>
  );
};

export default AccountInvoices;
