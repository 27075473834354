import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAuthToken } from "../../../services/auth";

export const fetchRewards = createAsyncThunk("rewards/fetch", async ({ shopId, count, page }: { shopId: string; count?: number; page?: number }) => {
  const token = getAuthToken();

  const response = await fetch(
    new Request(
      process.env.REACT_APP_API_ENTRYPOINT + `/shops/${shopId}/discounts?order[createdAt]=desc&itemsPerPage=${count || 10}&page=${page || 1}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
        cache: "no-cache",
      }
    )
  );

  if (!response.ok) {
    throw new Error("Erreur lors de la récupération des récompenses attribuées");
  }

  const data = await response.json();

  return data;
});
