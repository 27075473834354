import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import publicRoutes from "./router/public-routes";
import shopRoutes from "./router/shop-routes";

import AppLayout from "./components/layouts/AppLayout";
import ErrorPage from "./components/pages/Error";
import Home from "./components/pages/Home";
import { action as logoutAction } from "./components/pages/Logout";

import { tokenLoader } from "./services/auth";

const router = createBrowserRouter([
  {
    id: "app",
    path: "/",
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    loader: tokenLoader,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "logout",
        action: logoutAction,
      },
      ...shopRoutes,
    ],
  },
  ...publicRoutes,
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
