import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";

import ownerReducer from "./User/owner-slice";
import shopReducer from "./Shop/shop-slice";
import configRewardsReducer from "./Shop/ConfigReward/config-rewards-slice";
import rewardsReducer from "./Shop/Reward/rewards-slice";
import instagramReducer from "./Shop/Social/Instagram/instagram-slice";

const store = configureStore({
  reducer: {
    userOwner: ownerReducer,
    shop: shopReducer,
    shopConfigRewards: configRewardsReducer,
    shopRewards: rewardsReducer,
    shopInstagram: instagramReducer,
  },
  middleware: [...getDefaultMiddleware(), thunkMiddleware],
});

export default store;
