export type SubscriptionOfferOption = { option: number; context?: string; params?: object };

export enum SubscriptionOfferTypes {
  basic_collect = "basic_collect",
  basic_loyalty = "basic_loyalty",
  basic_all = "basic_all",

  pro_collect = "pro_collect",
  pro_loyalty = "pro_loyalty",
  pro_all = "pro_all",

  advanced_collect = "advanced_collect",
  advanced_loyalty = "advanced_loyalty",
  advanced_all = "advanced_all",

  enterprise_collect = "enterprise_collect",
  enterprise_loyalty = "enterprise_loyalty",
  enterprise_all = "enterprise_all",
}

export const SubscriptionOfferOptions = {
  [SubscriptionOfferTypes.basic_collect]: [{ option: 1 }, { option: 2 }, { option: 3 }],
  [SubscriptionOfferTypes.basic_loyalty]: [
    { option: 1 },
    { option: 13, context: "quota", params: { quota: 10 } },
    { option: 14, context: "count", params: { count: 1 } },
    { option: 2 },
    { option: 3 },
    { option: 11 },
    { option: 12 },
  ],
  [SubscriptionOfferTypes.basic_all]: [
    { option: 1 },
    { option: 2 },
    { option: 3 },
    { option: 13, context: "quota", params: { quota: 10 } },
    { option: 14, context: "count", params: { count: 1 } },
    { option: 11 },
    { option: 12 },
  ],

  [SubscriptionOfferTypes.pro_collect]: [{ option: 1 }, { option: 2 }, { option: 3 }, { option: 4 }, { option: 5 }],
  [SubscriptionOfferTypes.pro_loyalty]: [
    { option: 1 },
    { option: 13, context: "quota", params: { quota: 10 } },
    { option: 14, context: "count", params: { count: 1 } },
    { option: 2 },
    { option: 3 },
    { option: 11 },
    { option: 12 },
    { option: 4 },
    { option: 6 },
  ],
  [SubscriptionOfferTypes.pro_all]: [
    { option: 1 },
    { option: 2 },
    { option: 3 },
    { option: 13, context: "quota", params: { quota: 10 } },
    { option: 14, context: "count", params: { count: 1 } },
    { option: 11 },
    { option: 12 },
    { option: 4 },
    { option: 5 },
    { option: 6 },
  ],

  [SubscriptionOfferTypes.advanced_collect]: [{ option: 1 }, { option: 2 }, { option: 3 }, { option: 4 }, { option: 5 }, { option: 7 }],
  [SubscriptionOfferTypes.advanced_loyalty]: [
    { option: 1 },
    { option: 13, context: "quota", params: { quota: 50 } },
    { option: 14 },
    { option: 2 },
    { option: 3 },
    { option: 11 },
    { option: 12 },
    { option: 4 },
    { option: 6 },
    { option: 7 },
  ],
  [SubscriptionOfferTypes.advanced_all]: [
    { option: 1 },
    { option: 2 },
    { option: 3 },
    { option: 13, context: "quota" },
    { option: 14 },
    { option: 11 },
    { option: 12 },
    { option: 4 },
    { option: 5 },
    { option: 6 },
    { option: 7 },
  ],

  [SubscriptionOfferTypes.enterprise_collect]: [
    { option: 1 },
    { option: 2 },
    { option: 3 },
    { option: 4 },
    { option: 5 },
    { option: 7 },
    { option: 8 },
    { option: 9 },
  ],
  [SubscriptionOfferTypes.enterprise_loyalty]: [
    { option: 1 },
    { option: 13 },
    { option: 14 },
    { option: 2 },
    { option: 3 },
    { option: 11 },
    { option: 12 },
    { option: 4 },
    { option: 6 },
    { option: 7 },
    { option: 10 },
  ],
  [SubscriptionOfferTypes.enterprise_all]: [
    { option: 1 },
    { option: 2 },
    { option: 3 },
    { option: 13 },
    { option: 14 },
    { option: 11 },
    { option: 12 },
    { option: 4 },
    { option: 5 },
    { option: 6 },
    { option: 7 },
    { option: 8 },
    { option: 10 },
  ],
};

export const getSubscriptionOfferOptions = (type: keyof typeof SubscriptionOfferOptions): SubscriptionOfferOption[] => {
  return SubscriptionOfferOptions[type] || null;
};
