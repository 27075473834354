import React, { useEffect } from "react";
import { json, redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PublicLayout from "../layouts/PublicLayout";
import SigninForm from "../organisms/Signin/SigninForm";

const Signin = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("page.signin.meta_title");
  }, []);

  return (
    <PublicLayout withBackground>
      <h2 className="mb-3 fw-bold">{t("page.signin.title")}</h2>
      <SigninForm />
    </PublicLayout>
  );
};

export default Signin;

export async function action({ request }: { request: Request }) {
  const data = await request.formData();
  const authData = {
    email: data.get("email"),
    password: data.get("password"),
  };

  const response = await fetch(
    new Request(process.env.REACT_APP_API_ENTRYPOINT + "/v1/oauth", {
      method: "POST",
      body: JSON.stringify(authData),
      headers: new Headers({ "Content-Type": "application/json" }),
    })
  );

  if (!response.ok) {
    return json({ message: "account.errors.signin" }, { status: 500 });
  }

  const responseData = await response.json();
  if (!responseData.scopes.includes("ROLE_OWNER")) {
    return json({ message: "account.errors.signin" }, { status: 500 });
  }

  const expiration = new Date();
  expiration.setHours(expiration.getHours() + 3);
  localStorage.setItem("expiration", expiration.toISOString());
  localStorage.setItem("token", responseData.token);

  return redirect("/");
}
