import React from "react";
import { Outlet } from "react-router-dom";

import AccountNavigation from "../organisms/Layout/AccountNavigation";
import Socials from "../organisms/Social/Socials";

const AccountLayout = () => {
  return (
    <>
      <Socials />
      <AccountNavigation />
      <div className="py-3">
        <Outlet />
      </div>
    </>
  );
};

export default AccountLayout;
