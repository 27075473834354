import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../types/store";
import { createConfigReward, deleteConfigReward, fetchConfigRewards, fetchValidConfigRewards, updateConfigReward } from "./config-reward-actions";

interface ConfigRewardsState {
  configRewards: Array<any>; // @todo
  totalItems: number;
  validConfigRewards: Array<any>; // @todo
  validTotalItems: number;
  loading: boolean;
  error: string | null;
}

const initialState: ConfigRewardsState = {
  configRewards: [],
  totalItems: 0,
  validConfigRewards: [],
  validTotalItems: 0,
  loading: false,
  error: null,
};

const configRewardsSlice = createSlice({
  name: "configRewards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfigRewards.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.totalItems = 0;
      })
      .addCase(fetchConfigRewards.fulfilled, (state, action) => {
        state.loading = false;
        state.configRewards = action.payload["hydra:member"];
        state.totalItems = action.payload["hydra:totalItems"];
      })
      .addCase(fetchConfigRewards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.totalItems = 0;
      })
      .addCase(fetchValidConfigRewards.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.validTotalItems = 0;
      })
      .addCase(fetchValidConfigRewards.fulfilled, (state, action) => {
        state.loading = false;
        state.validConfigRewards = action.payload["hydra:member"];
        state.validTotalItems = action.payload["hydra:totalItems"];
      })
      .addCase(fetchValidConfigRewards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.validTotalItems = 0;
      })
      .addCase(createConfigReward.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createConfigReward.fulfilled, (state, action) => {
        state.loading = false;
        state.configRewards.push(action.payload);
      })
      .addCase(createConfigReward.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      })
      .addCase(updateConfigReward.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateConfigReward.fulfilled, (state, action) => {
        state.loading = false;
        state.configRewards = state.configRewards.map((configReward: any) => (configReward.id === action.payload.id ? action.payload : configReward));
      })
      .addCase(updateConfigReward.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      })
      .addCase(deleteConfigReward.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteConfigReward.fulfilled, (state, action) => {
        state.loading = false;

        const { configRewardId } = action.payload;

        if (configRewardId) {
          state.configRewards = state.configRewards.filter((configReward: any) => configReward.id !== configRewardId);
        }
      })
      .addCase(deleteConfigReward.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const {} = configRewardsSlice.actions;

export const selectConfigRewards = (state: RootState) => state.shopConfigRewards.configRewards;
export const selectTotalItems = (state: RootState) => state.shopConfigRewards.totalItems;
export const selectValidConfigRewards = (state: RootState) => state.shopConfigRewards.validConfigRewards;
export const selectValidTotalItems = (state: RootState) => state.shopConfigRewards.validTotalItems;
export const selectLoading = (state: RootState) => state.shopConfigRewards.loading;
export const selectError = (state: RootState) => state.shopConfigRewards.error;

export default configRewardsSlice.reducer;
