import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

import SocialLoginCard from "./SocialLoginCard";
import Button from "../../atoms/Button";
import Loader from "../../atoms/Loader";

import { selectLoading as selectShopLoading, selectShop } from "../../../stores/Shop/shop-slice";
import { fetchPublications } from "../../../stores/Shop/Social/Instagram/instagram-actions";
import { selectLoading as selectPublicationsLoading, selectPublications } from "../../../stores/Shop/Social/Instagram/instagram-slice";

const SocialCard = (props: { className?: string; header: string }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shop = useSelector(selectShop);
  const shopLoading = useSelector(selectShopLoading);

  const publications = useSelector(selectPublications);
  const publicationsLoading = useSelector(selectPublicationsLoading);

  const isLoading = shopLoading || publicationsLoading;

  useEffect(() => {
    if (shop && shop.instagramBusinessAccountId && shop.instagramBusinessAccountToken) {
      // @ts-ignore
      dispatch(
        // @ts-ignore
        fetchPublications({ shopInstagramId: shop.instagramBusinessAccountId, shopInstagramToken: shop.instagramBusinessAccountToken, count: 8 })
      );
    }
  }, [shop, dispatch]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loader />
      </div>
    );
  } else if (shop && !shop.instagramBusinessAccountId && !shop.instagramBusinessAccountToken) {
    return <SocialLoginCard header={props.header} />;
  }

  return (
    <>
      <div>
        <h3 className="fw-bold">{props.header}</h3>
      </div>
      <Card className={`h-100 rounded-4 ${props.className}`}>
        <Card.Body className="bg-light-subtle rounded-4 d-flex justify-content-center align-items-center">
          {shop ? (
            <div>
              <div className="instagram-username-social-card">
                <p className="instagram-username-social-card-text"> @{shop.instagramBusinessAccountName}</p>
              </div>
              <Row className="g-2 mb-1">
                {publications.map((publication: any) => (
                  <Col sm={4} md={3} lg={3} key={publication.id}>
                    {publication.media_type === "VIDEO" ? (
                      <video src={publication.media_url} controls style={{ width: "100%" }}></video>
                    ) : (
                      <Image src={publication.media_url} rounded fluid alt={`@${publication.username}`} />
                    )}
                  </Col>
                ))}
                {publications.length === 0 ? <Col>{t("publication.empty")}</Col> : null}
              </Row>
            </div>
          ) : null}
        </Card.Body>
        {publications.length > 0 ? (
          <Card.Footer className="bg-light-subtle rounded-bottom-4 border-0 text-center pb-4">
            <Button className="rounded-2 fw-bold" theme="secondary" label={t("publication.actions.view")} to="/instagram/publications" />
          </Card.Footer>
        ) : null}
      </Card>
    </>
  );
};

export default SocialCard;
