import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SocialLoginCard from "../../../organisms/Social/SocialLoginCard";
import Button from "../../../atoms/Button";
import Loader from "../../../atoms/Loader";
import PublicationCard from "../../../organisms/Social/PublicationCard";

import { fetchPublications } from "../../../../stores/Shop/Social/Instagram/instagram-actions";
import { selectShop } from "../../../../stores/Shop/shop-slice";
import { selectError, selectLoading, selectPublications, selectPublicationsNextUrl } from "../../../../stores/Shop/Social/Instagram/instagram-slice";

const Publications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shop = useSelector(selectShop);

  const publications = useSelector(selectPublications);
  const nextUrl = useSelector(selectPublicationsNextUrl);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

  useEffect(() => {
    document.title = t("page.publications.meta_title");
  }, []);

  useEffect(() => {
    if (shop && shop.instagramBusinessAccountId && shop.instagramBusinessAccountToken) {
      // @ts-ignore
      dispatch(fetchPublications({ shopInstagramId: shop.instagramBusinessAccountId, shopInstagramToken: shop.instagramBusinessAccountToken }));
    }
  }, [shop, dispatch]);

  const handleNextClick = () => {
    // @ts-ignore
    dispatch(
      // @ts-ignore
      fetchPublications({ shopInstagramId: shop.instagramBusinessAccountId, shopInstagramToken: shop.instagramBusinessAccountToken, next: nextUrl })
    );
  };

  if (error) {
    toast.error(t("errors.http"));
  }

  if (!loading && publications.length === 0) {
    return (
      <Row className="justify-content-center">
        <Col lg={6}>
          <SocialLoginCard header={t("social.instagram.login")} description={t("social.help")} className="text-center mt-5" />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row className="gy-4">
        {publications.map((publication: any, index: number) => (
          <Col lg={3} key={index}>
            <PublicationCard publication={publication} onList />
          </Col>
        ))}
      </Row>
      {loading ? (
        <Row>
          <Col>
            <div className="text-center mt-5">
              <Loader />
            </div>
          </Col>
        </Row>
      ) : null}
      {nextUrl ? (
        <Row>
          <Col>
            <div className="text-center mt-5">
              <hr />
              <Button theme="outline-tagether" label={t("actions.more")} onClick={handleNextClick} />
            </div>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default Publications;
