import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Tutorial = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("page.tutorial.meta_title");
  }, []);

  return (
    <>
      <h3 className="fw-bold mb-2">{t("page.tutorial.title")}</h3>
      <div className="tutorial-temporary-message-box">
        <h1 className="tutorial-temporary-message-title bg-primary text-center">🎥</h1>
        <h4 className="text-left fw-bold">{t("page.tutorial.subtitle")}</h4>
      </div>
    </>
  );
};

export default Tutorial;
