import Publications from "../components/pages/Shop/Social/publications";
import Publication from "../components/pages/Shop/Social/publication";
import Rewards from "../components/pages/Shop/Reward/Rewards";
import AccountSocials from "../components/pages/Shop/Account/AccountSocials";
import AccountInvoices from "../components/pages/Shop/Account/AccountInvoices";
import AccountOffer from "../components/pages/Shop/Account/AccountOffer";
import Tutorial from "../components/pages/Tutorial";
import SocialLayout from "../components/layouts/SocialLayout";
import AccountLayout from "../components/layouts/AccountLayout";
import AccountPassword from "../components/pages/Shop/Account/AccountPassword";
import ConfigRewardAdd from "../components/pages/Shop/Reward/ConfigRewardAdd";
import ConfigRewardEdit, { loader as configRewardEditLoader } from "../components/pages/Shop/Reward/ConfigRewardEdit";

export default [
  {
    path: ":social/publications",
    element: <SocialLayout />,
    children: [
      {
        index: true,
        element: <Publications />,
      },
      {
        path: ":id",
        element: <Publication />,
      },
    ],
  },
  {
    path: "rewards",
    element: <SocialLayout />,
    children: [
      {
        index: true,
        element: <Rewards />,
      },
      {
        path: ":type/add",
        element: <ConfigRewardAdd />,
      },
      {
        path: ":type/:id",
        element: <ConfigRewardEdit />,
        loader: configRewardEditLoader,
      },
    ],
  },
  {
    path: "account",
    element: <AccountLayout />,
    children: [
      {
        index: true,
        element: <AccountSocials />,
      },

      {
        path: "offer",
        element: <AccountOffer />,
      },
      {
        path: "invoices",
        element: <AccountInvoices />,
      },
      {
        path: "password",
        element: <AccountPassword />,
      },
    ],
  },
  {
    path: "tutorial",
    element: <Tutorial />,
  },
];
