import React from "react";
import { NavLink, Form } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Nav from "react-bootstrap/Nav";
import { House as HouseIcon } from "react-bootstrap-icons";
import { Instagram as SocialIcon } from "react-bootstrap-icons";
import { BookmarkStar as RewardIcon } from "react-bootstrap-icons";
import { Person as PersonIcon } from "react-bootstrap-icons";
import { Book as TutorialIcon } from "react-bootstrap-icons";

import Submit from "../../atoms/Submit";

const Navigation = () => {
  const { t } = useTranslation();

  return (
    <Nav variant="pills" defaultActiveKey="/" className="nav--sticky flex-column pt-5">
      <Nav.Link as={NavLink} to="/" className="icon-link mb-2 text-black">
        <HouseIcon />
        {t("nav.home")}
      </Nav.Link>
      <Nav.Link as={NavLink} to="/instagram/publications" className="icon-link mb-2 text-black">
        <SocialIcon />
        {t("nav.socials")}
      </Nav.Link>
      <Nav.Link as={NavLink} to="/rewards" className="icon-link mb-2 text-black">
        <RewardIcon />
        {t("nav.rewards")}
      </Nav.Link>
      <Nav.Link as={NavLink} to="/account" className="icon-link mb-2 text-black">
        <PersonIcon />
        {t("nav.account")}
      </Nav.Link>
      <Nav.Link as={NavLink} to="/tutorial" className="icon-link mb-2 text-black">
        <TutorialIcon />
        {t("nav.tutorial")}
      </Nav.Link>
      <Form action="/logout" method="post" className="nav-logout">
        <div>
          <Submit theme="tagether" label={t("actions.logout")} className="logout-button" />
        </div>
      </Form>
    </Nav>
  );
};

export default Navigation;
