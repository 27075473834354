export const generateCSRFToken = (length: number) => {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const randomBytes = new Uint8Array(length);
  const cryptoObj = window.crypto;

  if (cryptoObj && cryptoObj.getRandomValues) {
    cryptoObj.getRandomValues(randomBytes);
  } else {
    for (let i = 0; i < length; i++) {
      randomBytes[i] = Math.floor(Math.random() * charset.length);
    }
  }

  const token = Array.from(randomBytes)
    .map((byte) => charset[byte % charset.length])
    .join("");

  return token;
};
