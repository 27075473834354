import React, { useState } from "react";
import { useNavigation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Form from "react-bootstrap/Form";

import Submit from "../../atoms/Submit";

import { getAuthToken } from "../../../services/auth";

interface FormData {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

const AccountPasswordForm = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const user = useSelector((state: { userOwner: { user: any } }) => state.userOwner.user);

  const isSubmitting = navigation.state === "submitting";

  const [formData, setFormData] = useState<FormData>({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [formErrors, setFormErrors] = useState<Partial<FormData>>({});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const validateForm = () => {
    const errors: Partial<FormData> = {};

    if (!formData.password) {
      errors.password = t("form.account_password_form.password.error");
    }

    if (formData.newPassword.length < 6) {
      errors.newPassword = t("form.account_password_form.newPassword.error");
    }

    if (formData.newPassword !== formData.confirmPassword) {
      errors.confirmPassword = t("form.account_password_form.confirmPassword.error");
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const resetForm = () => {
    setFormData({
      password: "",
      newPassword: "",
      confirmPassword: "",
    });
    setFormErrors({});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const token = getAuthToken();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/v2/users/${user.id}/password`, {
        method: "PUT",
        body: JSON.stringify({ password: formData.password, newPassword: { first: formData.newPassword, second: formData.confirmPassword } }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }

      resetForm();

      toast.success(t("form.account_password_form.success"));
    } catch (error) {
      toast.error(t("errors.http"));
    }
  };

  return (
    <Form method="post" noValidate onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="accountUserPassword">
        <Form.Control
          type="password"
          name="password"
          placeholder={t("form.account_password_form.password.placeholder")}
          required
          value={formData.password}
          onChange={handleChange}
          isInvalid={!!formErrors.password}
        />
        <Form.Control.Feedback type="invalid">{formErrors.password}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="accountUserNewPassword">
        <Form.Control
          type="password"
          name="newPassword"
          placeholder={t("form.account_password_form.newPassword.placeholder")}
          required
          value={formData.newPassword}
          onChange={handleChange}
          isInvalid={!!formErrors.newPassword}
        />
        <Form.Control.Feedback type="invalid">{formErrors.newPassword}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="accountUserConfirmPassword">
        <Form.Control
          type="password"
          name="confirmPassword"
          placeholder={t("form.account_password_form.confirmPassword.placeholder")}
          required
          value={formData.confirmPassword}
          onChange={handleChange}
          isInvalid={!!formErrors.confirmPassword}
        />
        <Form.Control.Feedback type="invalid">{formErrors.confirmPassword}</Form.Control.Feedback>
      </Form.Group>

      <div className="text-end">
        <Submit theme="tagether" label={isSubmitting ? t("actions.loading") : t("form.account_password_form.submit")} loading={isSubmitting} />
      </div>
    </Form>
  );
};

export default AccountPasswordForm;
