import React, { FC } from "react";

import { Table as BootstrapTable } from "react-bootstrap";
import Loader from "../atoms/Loader";
import Pagination from "./Pagination";

interface TableProps {
  headers?: Array<any>;
  rows: Array<Array<any>>;
  loading?: boolean;
  theme?: string;
  paginate?: {
    active: number;
    pages: number;
    onPaginate: any;
  };
}

const Table: FC<TableProps> = ({ headers, rows, loading, theme, paginate }: TableProps) => {
  return (
    <>
      <BootstrapTable responsive>
        {headers ? (
          <thead>
            <tr className={`table-${theme}`}>
              {headers.map((header: any, index: number) => (
                <th key={index} className={header.className}>
                  {header.content}
                </th>
              ))}
            </tr>
          </thead>
        ) : null}
        <tbody>
          {rows.map((row: Array<any>, index: number) => (
            <tr key={index}>
              {row.map((cell: any, index: number) => (
                <td key={index} className={cell.className}>
                  {cell.content ?? "-"}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </BootstrapTable>
      {paginate ? <Pagination theme={theme} paginate={paginate} /> : null}
      {loading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : null}
    </>
  );
};

export default Table;
