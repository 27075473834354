import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import ConfigRewardCard from "../Reward/ConfigRewardCard";
import Submit from "../../atoms/Submit";
import Button from "../../atoms/Button";

import { getAuthToken } from "../../../services/auth";

import { RewardTypeLabels } from "../../../enums/RewardTypes";
import { selectShop } from "../../../stores/Shop/shop-slice";
import { fetchValidConfigRewards } from "../../../stores/Shop/ConfigReward/config-reward-actions";
import { selectValidConfigRewards, selectValidTotalItems } from "../../../stores/Shop/ConfigReward/config-rewards-slice";
import Pagination from "../../molecules/Pagination";

interface PublicationFormProps {
  publication: any;
  onSubmitted: any;
}

interface FormData {
  type: string;
  place: string;
  code: string;
  percent: string;
  description: string;
  validityStartAt: string;
  validityEndAt: string;
}

const PublicationForm: React.FC<PublicationFormProps> = ({ publication, onSubmitted }: PublicationFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shop = useSelector(selectShop);
  const configRewards = useSelector(selectValidConfigRewards);
  const totalItems = useSelector(selectValidTotalItems);

  const [page, setPage] = useState(1);

  const handleConfigRewardPaginate = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    if (shop) {
      // @ts-ignore
      dispatch(fetchValidConfigRewards({ shopId: shop.id, page: page }));
    }
  }, [dispatch, shop]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [formData, setFormData] = useState({
    configRewardSelected: "",
    type: "",
    place: "online",
    code: "",
    percent: 0,
    description: "",
    validityStartAt: "",
    validityEndAt: "",
  });

  const [formErrors, setFormErrors] = useState<Partial<FormData>>({});

  const isRewardCode = formData.type === "code";
  const isRewardPercent = formData.type === "percent";

  const rewardTypeKeys = Object.keys(RewardTypeLabels);

  const handleToggleSelect = (configReward: any) => {
    setFormData({
      configRewardSelected: configReward.id,
      type: configReward.type,
      place: configReward?.place ?? "online",
      code: configReward?.code ?? "",
      percent: configReward?.percent ?? 0,
      description: configReward?.description ?? "",
      validityStartAt: configReward.validity_start_at ?? "",
      validityEndAt: configReward.validity_end_at ?? "",
    });
  };

  const handleModeClick = () => {
    setIsCustom(!isCustom);
    resetForm();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const validateForm = () => {
    const errors: Partial<FormData> = {};

    if (isRewardCode) {
      if (!formData.code) {
        errors.code = t("reward.fields.code.error");
      }
    }

    if (isRewardPercent) {
      if (!formData.percent) {
        errors.percent = t("reward.fields.percent.error");
      }
    } else {
      if (!formData.description) {
        errors.description = t("reward.fields.description.error");
      }
    }

    if (formData.validityStartAt || formData.validityEndAt) {
      if (!formData.validityStartAt || !formData.validityEndAt) {
        errors.validityStartAt = t("reward.fields.validityStartAt.error.blank");
        errors.validityEndAt = t("reward.fields.validityEndAt.error.blank");
      } else {
        const startDate = new Date(formData.validityStartAt);
        const endDate = new Date(formData.validityEndAt);

        if (startDate > endDate) {
          errors.validityStartAt = t("reward.fields.validityStartAt.error.interval");
          errors.validityEndAt = t("reward.fields.validityEndAt.error.interval");
        }
      }
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const resetForm = () => {
    setFormData({
      configRewardSelected: "",
      type: "",
      place: "online",
      code: "",
      percent: 0,
      description: "",
      validityStartAt: "",
      validityEndAt: "",
    });
    setFormErrors({});
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    const token = getAuthToken();

    try {
      const productData = {
        mediaId: publication.id,
        mediaUrl: publication.media_url,
        username: publication.username,
        caption: publication.caption,
        permalink: publication.permalink,
        shop: shop["@id"],
        publish: true,
      };

      const productResponse = await fetch(
        new Request(process.env.REACT_APP_API_ENTRYPOINT + "/products", {
          method: "POST",
          body: JSON.stringify(productData),
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }),
        })
      );

      if (!productResponse.ok) {
        const errorMessage = await productResponse.text();
        throw new Error(errorMessage);
      }

      const product = await productResponse.json();

      const approveData = {
        config_discount_id: formData.configRewardSelected ? formData.configRewardSelected : null,
        type: formData.type ? formData.type : null,
        place: formData.place ? formData.place : null,
        code: formData.code ? formData.code : null,
        // @ts-ignore
        percent: formData.percent ? parseFloat(formData.percent) : null,
        description: formData.description ? formData.description : null,
        validity_start_at: formData.validityStartAt ? formData.validityStartAt : null,
        validity_end_at: formData.validityEndAt ? formData.validityEndAt : null,
        shop: shop["@id"],
      };

      const approveResponse = await fetch(
        new Request(process.env.REACT_APP_API_ENTRYPOINT + `/products/${product.id}/approve`, {
          method: "PUT",
          body: JSON.stringify(approveData),
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }),
        })
      );

      if (!approveResponse.ok) {
        const errorMessage = await approveResponse.text();
        throw new Error(errorMessage);
      }

      onSubmitted(approveData);

      toast.success(t("form.publication_form.success"));
    } catch (error) {
      toast.error(t("form.publication_form.error"));
    }

    setIsSubmitting(false);
  };

  return (
    <Form method="post" noValidate onSubmit={handleSubmit}>
      <Row className="justify-content-center gy-4">
        <Col lg={12}>
          <h4 className="fw-bold">{t("form.publication_form.title")}</h4>
          {isCustom ? (
            <>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4} className="fw-bold">
                  {t("reward.type.label")}
                </Form.Label>
                <Col sm={8}>
                  <Form.Control as="select" name="type" required value={formData.type} onChange={handleChange} isInvalid={!!formErrors.type}>
                    <option value="">{t("form.publication_form.type.placeholder")}</option>
                    {rewardTypeKeys.map((key) => (
                      <>
                        {shop.discountTypes.includes(key) && (
                          <option key={key} value={key}>
                            {t(`reward.type.${key}`)}
                          </option>
                        )}
                      </>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{t("form.publication_form.type.error")}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4} className="fw-bold">
                  {t("reward.place.label")}
                </Form.Label>
                <Col sm={4}>
                  <Form.Check
                    type="radio"
                    label={t("reward.place.online")}
                    value="online"
                    id="configRewardPlaceOnline"
                    name="place"
                    checked={formData.place === "online"}
                    onChange={handleChange}
                  />
                </Col>
                <Col sm={4}>
                  <Form.Check
                    type="radio"
                    label={t("reward.place.shop")}
                    value="shop"
                    id="configRewardPlaceShop"
                    name="place"
                    checked={formData.place === "shop"}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>

              {isRewardCode ? (
                <Form.Group as={Row} className="mb-3" controlId="configRewardCode">
                  <Form.Label column sm={4} className="fw-bold">
                    {t("reward.code.label")}
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control type="text" name="code" required value={formData.code} onChange={handleChange} isInvalid={!!formErrors.code} />
                    <Form.Control.Feedback type="invalid">{formErrors.code}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              ) : null}

              {isRewardPercent ? (
                <Form.Group as={Row} className="mb-3" controlId="configRewardPercent">
                  <Form.Label column sm={4} className="fw-bold">
                    {t("reward.percent.label")}
                  </Form.Label>
                  <Col sm={8}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="percent"
                        required
                        value={formData.percent}
                        onChange={handleChange}
                        isInvalid={!!formErrors.percent}
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">{formErrors.percent}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              ) : (
                <Form.Group as={Row} className="mb-3" controlId="configRewardDescription">
                  <Form.Label column sm={4} className="fw-bold">
                    {t("reward.description.label")}
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      name="description"
                      required
                      value={formData.description}
                      onChange={handleChange}
                      isInvalid={!!formErrors.description}
                    />
                    <Form.Control.Feedback type="invalid">{formErrors.description}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              )}

              <Form.Group as={Row} className="mb-3" controlId="configRewardValidity">
                <Form.Label column sm={4} className="fw-bold">
                  {t("reward.validity.label")}
                </Form.Label>
                <Col sm={4}>
                  {t("reward.validity.from")}{" "}
                  <Form.Control
                    type="date"
                    name="validityStartAt"
                    value={formData.validityStartAt}
                    onChange={handleChange}
                    isInvalid={!!formErrors.validityStartAt}
                  />
                  <Form.Control.Feedback type="invalid">{formErrors.validityStartAt}</Form.Control.Feedback>
                </Col>
                <Col sm={4}>
                  {t("reward.validity.to")}{" "}
                  <Form.Control
                    type="date"
                    name="validityEndAt"
                    value={formData.validityEndAt}
                    onChange={handleChange}
                    isInvalid={!!formErrors.validityEndAt}
                  />
                  <Form.Control.Feedback type="invalid">{formErrors.validityEndAt}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            </>
          ) : (
            <>
              <div className="overflow-auto mb-3" style={{ maxHeight: "380px" }}>
                {configRewards.map((configReward: any) => (
                  <ConfigRewardCard
                    key={configReward.id}
                    configReward={configReward}
                    isSelected={configReward.id === formData.configRewardSelected}
                    onToggleSelect={handleToggleSelect}
                  />
                ))}
              </div>
              <Pagination
                theme={"dark-blue"}
                paginate={{ active: page, pages: Math.ceil(totalItems / 10), onPaginate: handleConfigRewardPaginate }}
              />
            </>
          )}
          {shop && shop.discountTypes.length ? (
            <>
              <hr />
              <div className="d-flex justify-content-center align-items-center mb-3">
                <span>{t("reward.or")}</span>
                <Button
                  theme="link"
                  onClick={handleModeClick}
                  label={isCustom ? t("form.publication_form.select.config_reward") : t("form.publication_form.select.custom_reward")}
                  className="text-black"
                />
              </div>
            </>
          ) : null}
        </Col>

        <div className="text-end">
          <Button theme="outline-tagether" to="/instagram/publications" label={t("actions.back")} className="me-2" />
          <Submit theme="tagether" label={t("actions.submit.send")} loading={isSubmitting} />
        </div>
      </Row>
    </Form>
  );
};

export default PublicationForm;
