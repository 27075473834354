import React, { useEffect } from "react";
import { json, defer, useParams, useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ConfigRewardForm from "../../../organisms/Reward/ConfigRewardForm";

import { getAuthToken } from "../../../../services/auth";
import { RewardTypeEndpoints, getRewardTypeEndpoint } from "../../../../enums/RewardTypes";

interface ConfigRewardEditProps {}

const ConfigRewardEdit: React.FC = () => {
  const params = useParams();
  const data: any = useLoaderData();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("page.config_rewards.update.meta_title");
  }, []);

  return (
    <>
      <h3>{t("page.config_rewards.title")}</h3>
      <p className="text-center fw-bold p-5">{t("page.config_rewards.subtitle")}</p>
      <ConfigRewardForm type={params.type} configReward={data.configReward} />
    </>
  );
};

export default ConfigRewardEdit;

async function loadConfigReward(type: string, id: string) {
  const token = getAuthToken();
  const response = await fetch(
    new Request(process.env.REACT_APP_API_ENTRYPOINT + "/" + getRewardTypeEndpoint(type as keyof typeof RewardTypeEndpoints) + "/" + id, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    })
  );

  if (!response.ok) {
    throw json({ message: "config_reward.errors.get" }, { status: 500 });
  }

  const data = await response.json();

  return data;
}

export async function loader({ request, params }: { request: any; params: any }) {
  const type = params.type;
  const id = params.id;

  return defer({
    configReward: await loadConfigReward(type, id),
  });
}
