import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAuthToken } from "../../../services/auth";
import { RewardTypeEndpoints, getRewardTypeEndpoint } from "../../../enums/RewardTypes";

export const fetchConfigRewards = createAsyncThunk(
  "configRewards/fetch",
  async ({ shopId, count, page }: { shopId: string; count?: number; page?: number }) => {
    const token = getAuthToken();

    const response = await fetch(
      new Request(
        process.env.REACT_APP_API_ENTRYPOINT +
          `/shops/${shopId}/config_discounts?order[createdAt]=desc&itemsPerPage=${count || 10}&page=${page || 1}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }),
          cache: "no-cache",
        }
      )
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la récupération des récompenses configurées");
    }

    const data = await response.json();

    return data;
  }
);

export const fetchValidConfigRewards = createAsyncThunk(
  "configRewards/fetch/valid",
  async ({ shopId, count, page }: { shopId: string; count?: number; page?: number }) => {
    const token = getAuthToken();

    const response = await fetch(
      new Request(
        process.env.REACT_APP_API_ENTRYPOINT +
          `/shops/${shopId}/config_discounts?order[createdAt]=desc&itemsPerPage=${count || 10}&page=${page || 1}&valid=true`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }),
          cache: "no-cache",
        }
      )
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la récupération des récompenses configurées");
    }

    const data = await response.json();

    return data;
  }
);

export const createConfigReward = createAsyncThunk("configRewards/create", async (configRewardData: any) => {
  const token = getAuthToken();

  const response = await fetch(
    new Request(process.env.REACT_APP_API_ENTRYPOINT + `/${getRewardTypeEndpoint(configRewardData.type as keyof typeof RewardTypeEndpoints)}`, {
      method: "POST",
      body: JSON.stringify(configRewardData),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    })
  );

  if (!response.ok) {
    throw new Error("Erreur lors de la création de la récompense configurée");
  }

  const data = await response.json();

  return data;
});

export const updateConfigReward = createAsyncThunk("configRewards/update", async (configRewardData: any) => {
  const token = getAuthToken();

  const response = await fetch(
    new Request(
      process.env.REACT_APP_API_ENTRYPOINT +
        `/${getRewardTypeEndpoint(configRewardData.type as keyof typeof RewardTypeEndpoints)}/${configRewardData.id}`,
      {
        method: "PUT",
        body: JSON.stringify(configRewardData),
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      }
    )
  );

  if (!response.ok) {
    throw new Error("Erreur lors de la modification de la récompense configurée");
  }

  const data = await response.json();

  return data;
});

export const deleteConfigReward = createAsyncThunk("configRewards/delete", async (configRewardId: string) => {
  const token = getAuthToken();

  const response = await fetch(
    new Request(process.env.REACT_APP_API_ENTRYPOINT + `/config_discounts/${configRewardId}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    })
  );

  if (!response.ok) {
    throw new Error("Erreur lors de la suppression de la récompense configurée");
  }

  return { configRewardId };
});
