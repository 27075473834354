import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";

import checkIconImage from "../../../../assets/images/icons/icon-check.png";

import { selectShop } from "../../../../stores/Shop/shop-slice";
import { SubscriptionOfferOption, getSubscriptionOfferOptions } from "../../../../enums/SubscriptionOfferType";

const AccountOffer = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("page.account.offer.meta_title");
  }, []);

  const shop = useSelector(selectShop);
  const [subscriptionOfferType, setSubscriptionOfferType] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [subscriptionTypeLabel, setSubscriptionTypeLabel] = useState(null);
  const [subscriptionTypeIntervalLabel, setSubscriptionTypeIntervalLabel] = useState(null);
  const [subscriptionAmount, setSubscriptionAmount] = useState(null);
  const [subscriptionOptions, setSubscriptionOptions] = useState<SubscriptionOfferOption[]>([]);

  useEffect(() => {
    if (shop) {
      setSubscriptionOfferType(shop.subscriptionOfferType);
      setSubscriptionType(shop.subscriptionType);
      setSubscriptionAmount(shop.subscriptionAmount);
    }
  }, [shop]);

  useEffect(() => {
    if (subscriptionOfferType) {
      const subscriptionOptions = getSubscriptionOfferOptions(subscriptionOfferType);
      setSubscriptionOptions(subscriptionOptions);
    }
  }, [subscriptionOfferType]);

  useEffect(() => {
    if (subscriptionType) {
      // @ts-ignore
      setSubscriptionTypeLabel(t(`offer.pricing.${subscriptionType}`));
      // @ts-ignore
      setSubscriptionTypeIntervalLabel(t(`offer.pricing.${subscriptionType}`, { context: "interval" }));
    }
  }, [subscriptionType]);

  return (
    <>
      <h3>{t("page.account.offer.title")}</h3>
      <Row className="gy-4">
        <Col lg={6}>
          <h5>{t("offer.subscription.title")}</h5>
          <ListGroup>
            {subscriptionOptions.map((subscriptionOption: SubscriptionOfferOption, index: number) => (
              <ListGroup.Item key={index}>
                <div>
                  <Image src={checkIconImage} alt="" width="32px" className="me-1" />
                  {t(`offer.options.option_${subscriptionOption.option}`, { context: subscriptionOption?.context, ...subscriptionOption?.params })}
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col lg={6}>
          <h5>{t("offer.pricing.title")}</h5>
          {subscriptionType && subscriptionAmount ? (
            <ListGroup>
              <ListGroup.Item>
                <div>
                  <Image src={checkIconImage} alt="" width="32px" className="me-1" />
                  {subscriptionAmount} € / {subscriptionTypeIntervalLabel ?? ""}
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>
                  <Image src={checkIconImage} alt="" width="32px" className="me-1" />
                  {subscriptionTypeLabel ?? ""}
                </div>
              </ListGroup.Item>
            </ListGroup>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default AccountOffer;
