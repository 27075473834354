import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

import Button from "../../atoms/Button";
import Loader from "../../atoms/Loader";

import checkIconImage from "../../../assets/images/icons/icon-check.png";

import { getAuthToken } from "../../../services/auth";
import { selectShop } from "../../../stores/Shop/shop-slice";

interface PublicationCardProps {
  publication: any;
  onList?: boolean;
}

const PublicationCard: React.FC<PublicationCardProps> = ({ publication, onList }: PublicationCardProps) => {
  const { t } = useTranslation();
  const token = getAuthToken();
  const shop = useSelector(selectShop);

  const handlePublicationClick = () => {
    window.open(publication.permalink, "_blank");
  };

  const [canReward, setCanReward] = useState(false);
  const [reward, setReward] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      new Request(process.env.REACT_APP_API_ENTRYPOINT + `/v2/discounts/publication/${publication.id}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      })
    )
      .then((response) => response.json())
      .then((data) => {
        setReward(data);
        setLoading(false);
        setCanReward(true);
      })
      .catch((error) => setCanReward(false));
  }, []);

  return (
    <Card>
      <Card.Header className="bg-white" style={{ cursor: "pointer" }} onClick={handlePublicationClick}>
        {/* <Image src={publication.media_url} alt={publication.username} roundedCircle width="40px" height="40px" className="object-fit-cover me-3" /> */}
        <span className="fw-bold">@{publication.username}</span>
      </Card.Header>
      {publication.media_type === "VIDEO" ? (
        <video src={publication.media_url} controls style={{ height: onList ? "244px" : "" }}></video>
      ) : (
        <Card.Img
          variant="middle"
          src={publication.media_url}
          alt={publication.caption}
          className={`object-fit-cover ${reward ? "opacity-50" : ""}`}
          style={{ height: onList ? "244px" : "", cursor: "pointer" }}
          onClick={handlePublicationClick}
        />
      )}
      {onList ? (
        <Card.Body className="text-end">
          {loading ? (
            <>
              <Loader isSmall />
            </>
          ) : (
            <>
              {reward ? (
                <Image src={checkIconImage} alt={t("publication.rewarded")} width="32px" />
              ) : (
                <>
                  {canReward && (
                    <Button
                      theme="tagether"
                      to={`/instagram/publications/${publication.id}`}
                      label={t("actions.retain")}
                      className={`btn-sm ${shop && shop.quota && shop.quotaRemaining === 0 ? "disabled" : ""}`}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Card.Body>
      ) : (
        <Card.Body>
          <Card.Text>{publication.caption}</Card.Text>
        </Card.Body>
      )}
    </Card>
  );
};

export default PublicationCard;
