import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLoaderData, useSubmit } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Header from "../organisms/Layout/Header";
import Navigation from "../organisms/Layout/Navigation";

import { getTokenDuration } from "../../services/auth";
import { fetchOwner } from "../../stores/User/owner-actions";
import { selectUser } from "../../stores/User/owner-slice";
import { fetchShop } from "../../stores/Shop/shop-actions";

const AppLayout = () => {
  const token = useLoaderData();
  const submit = useSubmit();
  const dispatch = useDispatch();

  const owner = useSelector(selectUser);

  useEffect(() => {
    if (!token || token === "EXPIRED") {
      submit(null, { action: "/logout", method: "post" });
      return;
    }

    const tokenDuration = getTokenDuration();

    setTimeout(() => {
      submit(null, { action: "/logout", method: "post" });
    }, tokenDuration);

    // @ts-ignore
    dispatch(fetchOwner());
  }, [token, submit, dispatch]);

  useEffect(() => {
    if (owner) {
      // @ts-ignore
      dispatch(fetchShop({ shopId: owner.shop.id }));
    }
  }, [owner, dispatch]);

  return (
    <>
      <Header />
      <div className="webapp-layout">
        <div className="hero-webapp-layout">
          <div className="navigation-section">
            <Navigation />
          </div>
          <div className="content-section">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AppLayout;
