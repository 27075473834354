import { createSlice, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/store";
import { fetchAccount, disconnectAccount, fetchPublication, fetchPublications } from "./instagram-actions";

interface InstagramState {
  account: any; // @todo
  publications: Array<any>; // @todo
  publicationsNextUrl: string | null;
  publication: null;
  loading: boolean;
  error: string | null;
}

const initialState: InstagramState = {
  account: null,
  publications: [],
  publicationsNextUrl: null,
  publication: null,
  loading: false,
  error: null,
};

const instagramSlice = createSlice({
  name: "instagram",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.account = action.payload;
      })
      .addCase(fetchAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      })
      .addCase(disconnectAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(disconnectAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.account = null;
        state.publications = [];
        state.publicationsNextUrl = null;
        state.publication = null;
      })
      .addCase(disconnectAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      })
      .addCase(fetchPublications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPublications.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload.toAdd) {
          // @ts-ignore
          state.publications.push(...action.payload.response.data);
        } else {
          state.publications = action.payload.response.data;
        }

        state.publicationsNextUrl = action.payload.response.paging?.next ?? null;
      })
      .addCase(fetchPublications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      })
      .addCase(fetchPublication.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPublication.fulfilled, (state, action) => {
        state.loading = false;
        state.publication = action.payload;
      })
      .addCase(fetchPublication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const {} = instagramSlice.actions;

export const getPublicationById = (id: string) =>
  createSelector(
    (state: RootState) => state.shopInstagram.publications,
    (publications: any) => publications.find((publication: any) => publication.id === id)
  );

export const selectAccount = (state: RootState) => state.shopInstagram.account;
export const selectPublications = (state: RootState) => state.shopInstagram.publications;
export const selectPublicationsNextUrl = (state: RootState) => state.shopInstagram.publicationsNextUrl;
export const selectPublication = (state: RootState) => state.shopInstagram.publication;
export const selectLoading = (state: RootState) => state.shopInstagram.loading;
export const selectError = (state: RootState) => state.shopInstagram.error;

export default instagramSlice.reducer;
