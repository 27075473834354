import React from "react";
import Spinner from "react-bootstrap/Spinner";

interface LoaderProps {
  isSmall?: boolean;
}

const Loader: React.FC<LoaderProps> = ({ isSmall }: LoaderProps) => {
  let optionProps: { size?: "sm" } = {};

  if (isSmall) {
    optionProps.size = "sm";
  }

  return (
    <Spinner as="span" animation="border" role="status" aria-hidden="true" {...optionProps}>
      <span className="visually-hidden">Chargement...</span>
    </Spinner>
  );
};

export default Loader;
