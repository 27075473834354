import React, { useEffect, useState } from "react";
import { Form as RouterForm, useActionData, useNavigation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import Submit from "../../atoms/Submit";
import Button from "../../atoms/Button";
import Loader from "../../atoms/Loader";

import { selectCategories, selectError, selectLoading } from "../../../stores/Shop/shop-slice";
import { fetchCategories } from "../../../stores/Shop/shop-actions";

interface ErrorResponseForm {
  message: string;
}

const SignupForm = () => {
  const data: ErrorResponseForm = useActionData() as ErrorResponseForm;
  const navigation = useNavigation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const categories = useSelector(selectCategories);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

  const isSubmitting = navigation.state === "submitting";

  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isTouchedPhoneNumber, setIsTouchedPhoneNumber] = useState(false);

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPhoneNumber = event.target.value;
    setPhoneNumber(newPhoneNumber);
    setIsTouchedPhoneNumber(true);

    const regex = /^(0|\+33)[12345679][0-9]{8}$/;
    setIsValidPhoneNumber(regex.test(newPhoneNumber));
  };

  const [category, setCategory] = useState("");
  const [isTouchedCategory, setIsTouchedCategory] = useState(false);

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCategory = event.target.value;
    setCategory(newCategory);
    setIsTouchedCategory(true);
  };

  const [postalCode, setPostalCode] = useState("");
  const [isValidPostalCode, setIsValidPostalCode] = useState(false);
  const [isTouchedPostalCode, setIsTouchedPostalCode] = useState(false);

  const handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPostalCode = event.target.value;
    setPostalCode(newPostalCode);
    setIsTouchedPostalCode(true);

    const regex = /[0-9]{5}/;
    setIsValidPostalCode(regex.test(newPostalCode));
  };

  const [password, setPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isTouchedPassword, setIsTouchedPassword] = useState(false);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setIsTouchedPassword(true);

    if (newPassword.length >= 6) {
      setIsValidPassword(true);
    } else {
      setIsValidPassword(false);
    }
  };

  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(false);
  const [isTouchedConfirmPassword, setIsTouchedConfirmPassword] = useState(false);

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    setIsTouchedConfirmPassword(true);

    if (newConfirmPassword === password) {
      setIsValidConfirmPassword(true);
    } else {
      setIsValidConfirmPassword(false);
    }
  };

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      toast(data.message);
    }
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Alert variant="danger">
        <Alert.Heading as="h5">{t("errors.http")}</Alert.Heading>
      </Alert>
    );
  }

  return (
    <Form method="post" as={RouterForm} noValidate validated={validated} onSubmit={handleSubmit} onChange={() => setValidated(false)}>
      <Row>
        <Col lg={6}>
          <p className="text-start fw-bold mb-2">{t("form.signup_form.shop.label")} :</p>
          <Form.Group className="mb-3" controlId="signupShopName">
            <Form.Control type="text" name="name" placeholder={t("form.signup_form.shop.name.placeholder")} required />
            <Form.Control.Feedback type="invalid">{t("form.signup_form.shop.name.error")}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="signupShopPhoneNumber">
            <Form.Control
              type="text"
              name="phoneNumber"
              placeholder={t("form.signup_form.shop.phoneNumber.placeholder")}
              required
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              isInvalid={!isValidPhoneNumber && isTouchedPhoneNumber}
            />
            <Form.Control.Feedback type="invalid">{t("form.signup_form.shop.phoneNumber.error")}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="signupShopCategory">
            <Form.Control
              as="select"
              name="category"
              required
              value={category}
              onChange={handleCategoryChange}
              isInvalid={category === "" && isTouchedCategory}
            >
              <option value="">{t("form.signup_form.shop.category.placeholder")}</option>
              {categories.map((category: any) => (
                <option value={category.id} key={category.id}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{t("form.signup_form.shop.category.error")}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="signupShopAddressFirstRow">
            <Form.Control type="text" name="addressFirstRow" placeholder={t("form.signup_form.shop.addressFirstRow.placeholder")} required />
            <Form.Control.Feedback type="invalid">{t("form.signup_form.shop.addressFirstRow.error")}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="signupShopAddressSecondRow">
            <Form.Control type="text" name="addressSecondRow" placeholder={t("form.signup_form.shop.addressSecondRow.placeholder")} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="signupShopAddressPostalCode">
            <Form.Control
              type="text"
              name="addressPostalCode"
              placeholder={t("form.signup_form.shop.addressPostalCode.placeholder")}
              required
              value={postalCode}
              onChange={handlePostalCodeChange}
              isInvalid={!isValidPostalCode && isTouchedPostalCode}
            />
            <Form.Control.Feedback type="invalid">{t("form.signup_form.shop.addressPostalCode.error")}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="signupShopAddressCity">
            <Form.Control type="text" name="addressCity" placeholder={t("form.signup_form.shop.addressCity.placeholder")} required />
            <Form.Control.Feedback type="invalid">{t("form.signup_form.shop.addressCity.error")}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <p className="text-start fw-bold mb-2">{t("form.signup_form.user.label")} :</p>
          <Form.Group className="mb-3" controlId="signupUserName">
            <Form.Control type="text" name="userName" placeholder={t("form.signup_form.user.userName.placeholder")} required />
            <Form.Control.Feedback type="invalid">{t("form.signup_form.user.userName.error")}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="signupUserEmail">
            <Form.Control type="email" name="userEmail" placeholder={t("form.signup_form.user.userEmail.placeholder")} required />
            <Form.Control.Feedback type="invalid">{t("form.signup_form.user.userEmail.error")}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="signupUserPassword">
            <Form.Control
              type="password"
              name="userPassword"
              placeholder={t("form.signup_form.user.userPassword.placeholder")}
              required
              value={password}
              onChange={handlePasswordChange}
              isInvalid={!isValidPassword && isTouchedPassword}
            />
            <Form.Control.Feedback type="invalid">{t("form.signup_form.user.userPassword.error")}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="signupUserConfirmPassword">
            <Form.Control
              type="password"
              name="userConfirmPassword"
              placeholder={t("form.signup_form.user.userConfirmPassword.placeholder")}
              required
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              isInvalid={!isValidConfirmPassword && isTouchedConfirmPassword}
            />
            <Form.Control.Feedback type="invalid">{t("form.signup_form.user.userConfirmPassword.error")}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <div className="d-grid mt-3">
        <Submit theme="tagether" label={t("form.signup_form.submit")} loading={isSubmitting} />
        <hr />
        <Button theme="outline-tagether" label={t("form.signin_form.submit")} to={"/signin"} />
      </div>
    </Form>
  );
};

export default SignupForm;
